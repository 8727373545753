import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Layout, Sections } from 'components';
import { LanguageContext } from 'context';

// markup 404 page
const NotFoundPage = ({ data }) => {
  const pageContext = { slug: '404' };
  const page = data.contentfulWebPage;
  const sections = page.sections;
  const menuItems = data.allContentfulWebSite.nodes
    .flatMap((node) => node.pages.flatMap((p) => [p, p.subPages]))
    .flat()
    .filter(Boolean)
    .filter((item) => item.menuRelated);
  const topMenuItems = data.allContentfulWebSite.nodes
    .flatMap((node) => node.pages.flatMap((p) => [p, p.subPages]))
    .flat()
    .filter(Boolean)
    .filter((item) => item.topMenuRelated);
  const headerItems = data.contentfulWebHeaderNavbar;
  const staticData = data.allContentfulDictionary.nodes;

  useEffect(() => {
    document.documentElement.setAttribute('dir', 'rtl');
    document.documentElement.setAttribute('lang', 'ar');
    document.body.classList.add('rtl');
  }, []);

  return (
    <LanguageContext.Provider
      value={{
        currentLang: 'ar-SA',
        staticData,
      }}
    >
      <Layout pageContext={pageContext} menuItems={menuItems} headerItems={headerItems} topMenuItems={topMenuItems}>
        <Sections sections={sections} />
      </Layout>
    </LanguageContext.Provider>
  );
};

export default NotFoundPage;

export const query = graphql`
  query {
    contentfulWebPage(slug: { eq: "404" }, node_locale: { eq: "ar-SA" }) {
      id
      sections {
        title
        cssWrapper
        components {
          ... on ContentfulWebLatestNewsComponent {
            title
            type
            articleCategory {
              slug
              article {
                id
                slug
                articleContent {
                  articleContent
                }
                title
                shortDescription
                articleImage {
                  gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
                  description
                }
                articleDate(formatString: "DD MMMM, Y")
                featured
              }
            }
            buttonTab {
              label
              link
              gtLabel
              gtEvent
              gtAction
              gtEventCategory
            }
          }
          ... on ContentfulWebSponsorCarousel {
            type
            title
            sponsorsItems {
              id
              title
              logoImage {
                description
                gatsbyImageData(quality: 90, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
          ... on ContentfulWebNotFoundComponent {
            type
            title
            mainTitle
            description {
              description
            }
            backgroundImage {
              gatsbyImageData(quality: 90, placeholder: BLURRED, layout: FULL_WIDTH)
            }
            image {
              description
              gatsbyImageData(quality: 90, placeholder: BLURRED, layout: CONSTRAINED)
            }
            button {
              link
              label
            }
          }
        }
      }
    }
    allContentfulDictionary(filter: { node_locale: { eq: "ar-SA" } }) {
      nodes {
        contentItems {
          dictionary {
            title
          }
          keyString
          stringValue {
            stringValue
          }
        }
      }
    }
    allContentfulWebSite(
      filter: { webSlug: { eq: "smc-site" }, node_locale: { eq: "ar-SA" } }
      sort: { order: ASC, fields: createdAt }
    ) {
      nodes {
        title
        webSlug
        pages {
          node_locale
          slug
          title
          id
          menuRelated
          topMenuRelated
          gtAction
          gtEvent
          gtEventCategory
          gtLabel
          subPages {
            ... on ContentfulWebPage {
              id
              slug
              title
              gtAction
              gtEvent
              gtEventCategory
              gtLabel
            }
          }
        }
      }
    }
    contentfulWebHeaderNavbar(node_locale: { eq: "ar-SA" }) {
      startDateCompetition
      days
      hours
      minutes
      seconds
      raceDate
      language
      newsItem
      logoImage {
        description
        file {
          url
        }
      }
      coloredLogoImage {
        description
        file {
          url
        }
      }
    }
  }
`;
